import React, { lazy, Suspense } from 'react';
import './App.css';
import { Switch, Route, HashRouter } from 'react-router-dom';
// import NcssView from './pages/nscc/NcssView';
import './assets/css/Bootstrap.css';
import './assets/css/font-awesome.css';
const Layout = lazy(() => import('./shared/Layout'));
const Login = lazy(() => import('./pages/authentication/Login'));
const NcssContent = lazy(() => import('./pages/ncss/NcssContent'));
const LoginNCSS = lazy(() => import('./pages/authentication/LoginNcss'))
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
function App() {
  return (
    <div className="App">
      <HashRouter>
        <Suspense fallback={loading}>
          <Switch>
            <Route path="/LoginNCSS" name="Login" render={props => <Login {...props} />} />
            <Route path="/LoginCybots" name="Login" render={props => <LoginNCSS {...props} />} />
            <Route path="/NcssContent" name="NcssContent" render={props => <NcssContent {...props} />} />
            <Route path="/" name="Layout" render={props => <Layout {...props} />} />
          </Switch>
        </Suspense>
      </HashRouter>
    </div>

  );
}

export default App;
